<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="m-t">
      <e-row class="erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border v-model="tribunal.id" disable />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row>

        <e-col style="max-width: 400px">
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="110px"
          >
            <erp-input simple-border v-model="tribunal.nome" required autofocus />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row>
        <e-col style="max-width: 400px">
          <erp-s-field
              view="lr"
              label="Site:"
              label-width="110px"
          >
            <erp-input simple-border v-model="tribunal.site" required />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col style="max-width: 400px">
          <uf-select field-view="lr" label="UF:" label-width="110px" v-model="tribunal.uf" />
        </e-col>
      </e-row>

      <e-row>
        <e-col style="max-width: 400px">
          <erp-s-field
              view="lr"
              label-width="110px"
              label="Descrição:"
              class="wrap label-wrap"
          >
            <textarea v-model="tribunal.descricao" class="full-width wrapper-xs" rows="4" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Ativar busca do robô:"
              label-width="110px"
          >
            <erp-checkbox v-model="tribunal.buscaRoboAtivada" />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>

    <window-footer class="flex content-between">
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <slot name="success">
      <div class="text-center">
        <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
      </div>
      <h5 class="m-t" v-if="id"> Atualizado com sucesso!</h5>
      <h5 class="m-t" v-else>Registrado com sucesso!</h5>
      <div class="text-center m-t">
        <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
      </div>
    </slot>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else></window-loading>
</template>

<script>
import {
  WindowContent,
  WindowFooter,
  ErpSField,
  ErpInput,
  ErpCheckbox
  // ErpSelect
} from 'uloc-vue-plugin-erp'
import WindowLoading from "../../../../layout/window/Loading";
import ERow from "../../../../layout/components/Row";
import ECol from "../../../../layout/components/Col";
import {newTribunal, updateTribunal, find} from "../../../../../domain/processos/services/tribunal";
import UfSelect from "../../../../remocao/components/include/UfSelect";
import WindowSuccess from "../../../../layout/window/Success";
import TimerCloseWindow from "../../../../layout/window/TimerClose";

export default {
  name: 'TribunalWindow',
  props: ['id'],
  components: {
    TimerCloseWindow,
    WindowSuccess,
    UfSelect,
    WindowContent,
    WindowFooter,
    ErpSField,
    ErpInput,
    ERow,
    ECol,
    WindowLoading,
    ErpCheckbox
    // ErpSelect
  },
  data () {
    return {
      tribunal: {
        id: null,
        nome: null,
        descricao: null,
        uf: null,
        buscaRoboAtivada: false
      },
      loading: !!this.id,
      status: null,
      success: null,
      errors: null
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      this.id && find(this.id)
          .then(response => {
            this.tribunal = response.data
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar os dados do tribunal, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    save () {
      this.loading = true
      this.status = 'Processando dados do tribunal'
      const data = {...this.tribunal}
      const method = data.id ? updateTribunal(data.id, data) : newTribunal(data)
      method
          .then(({data}) => {
            this.tribunal.id = data.id
            this.success = true
            this.$nextTick(() => {
              // this.$refs.timer.start()
              window.setTimeout(() => {
                this.loading = false
                this.success = false
                this.load()
              }, 3000)
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            })
          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            // this.errors = this.systemError(error)
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
              // this.error = true
            })
          })
    },
    open () {
      console.log('TODO open')
    }
  }
}
</script>
